import React, { Component } from "react";
import { connect } from "react-redux";
import { CustomSingleChoice, CustomMultipleChoice } from "./Components";
import { Button } from "@mui/material";
import {
  eligibleSurveyComponentTypes,
  ORGANIZATION_PORTAL_URL,
  PAGE_NAME,
  status,
  LOCALSTORAGE_ITEMS,
} from "Constants";
import {
  getSecondaryCriteriaForSurvey,
  saveSecondaryCriteria,
} from "ReduxStore/Survey/SurveyThunk";
import Header from "Postlogin/Components/Header";
import { Loading } from "Postlogin/Components/Loading";
import { LoadingButton } from "@mui/lab";
import { alert } from "Utils/alert";
import axios from "axios";
import { Box } from "@mui/material";
import { LocalStorageService } from "Utils/HelperFunctions";
import { navigateRouter } from "Utils/navigateRouter";

class EligibleSurvey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentQuestion: 0,
      questions: [],
      showSubmitBtn: false,
      isDisabled: false,
    };
  }

  componentDidMount() {
    const surveyId = this.props.params.surveyId;
    const token = this.props.params.token;
    if (token && surveyId) {
      this.getSurveyDetails(surveyId, token);
    } else if (surveyId) {
      this.props.getSecondaryCriteriaForSurvey(surveyId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.getSecondaryCriteriaForSurveyRes.status !==
      prevProps.getSecondaryCriteriaForSurveyRes.status
    ) {
      if (
        this.props.getSecondaryCriteriaForSurveyRes.status === status.SUCCESS
      ) {
        const questions =
          this.props.getSecondaryCriteriaForSurveyRes.data || {};
        const manipulatedData = this.manipulateData(
          JSON.parse(JSON.stringify(questions))
        );
        this.setState({
          questions: manipulatedData,
          currentQuestion: 0,
        });
      } else if (
        this.props.getSecondaryCriteriaForSurveyRes.status === status.FAILURE
      ) {
        alert.error(this.props.getSecondaryCriteriaForSurveyRes.data);
      }
    }

    if (
      this.props.saveSecondaryCriteriaRes.status !==
      prevProps.saveSecondaryCriteriaRes.status
    ) {
      if (this.props.saveSecondaryCriteriaRes.status === status.SUCCESS) {
        alert.success(this.props.saveSecondaryCriteriaRes.data.message);
        this.props.navigate(`/app/postlogin/${PAGE_NAME.DASHBOARD}`, {
          replace: true,
        });
      } else if (
        this.props.saveSecondaryCriteriaRes.status === status.FAILURE
      ) {
        alert.error(this.props.saveSecondaryCriteriaRes.data);
      }
    }
  }

  manipulateData = (questions) => {
    questions.forEach((question) => {
      const { type } = question;
      if (type === eligibleSurveyComponentTypes.MULTIPLE_CHOICE) {
        question.answer = [];
      } else if (type === eligibleSurveyComponentTypes.SINGLE_CHOICE) {
        question.answer = "";
      }
      question.isShow = true;
    });
    return questions;
  };

  handleStateChange = (answer) => {
    let { currentQuestion, questions } = this.state;
    if (questions && questions.length > 0) {
      const question = questions[currentQuestion];
      question.answer = answer;
      this.setState({
        questions,
      });
    }
  };

  getSurveyDetails = async (surveyId, token) => {
    try {
      let response = await axios.get(
        `${ORGANIZATION_PORTAL_URL}/organization/survey/individual/${surveyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const questions = response.data.data.surveys || {};
      const manipulatedData = this.manipulateData(
        JSON.parse(JSON.stringify(questions))
      );
      this.setState({
        questions: manipulatedData,
        currentQuestion: 0,
      });
    } catch (error) {
      console.log(error);
    }
  };

  renderQuestions = () => {
    const { currentQuestion, questions } = this.state;
    if (questions?.length > 0) {
      const question = questions[currentQuestion];

      const styleDetails = questions.styleDetails ? questions.styleDetails : {};
      const type = question.questionType;
      if (
        type === eligibleSurveyComponentTypes.MULTIPLE_CHOICE &&
        question.isShow
      ) {
        return (
          <CustomMultipleChoice
            {...question}
            styleDetails={styleDetails}
            handleStateChange={this.handleStateChange}
            isDisabled={this.state.isDisabled}
          />
        );
      } else if (
        type === eligibleSurveyComponentTypes.SINGLE_CHOICE &&
        question.isShow
      ) {
        return (
          <CustomSingleChoice
            {...question}
            styleDetails={styleDetails}
            handleStateChange={this.handleStateChange}
            isDisabled={this.state.isDisabled}
          />
        );
      }
      return <></>;
    }
  };

  onClickNext = async () => {
    const { currentQuestion, questions } = this.state;
    if (currentQuestion !== questions.length - 1) {
      this.setState({ currentQuestion: currentQuestion + 1 });
    }
    if (currentQuestion === questions.length - 1) {
      const userData =
        JSON.parse(LocalStorageService.getData(LOCALSTORAGE_ITEMS.userData)) ||
        {};
      this.props.saveSecondaryCriteria({
        surveyId: this.props.params.surveyId,
        respondentId: userData._id,
        secondaryCriteria: questions,
      });
    }
  };

  onClickPrevious = () => {
    let { currentQuestion } = this.state;
    if (currentQuestion - 1 >= 0) {
      currentQuestion = currentQuestion - 1;
      this.setState({
        currentQuestion,
        showSubmitBtn: false,
      });
    }
  };

  onClickBackToDashboard = () => {
    this.props.navigate("/app/postlogin/dashboard", { replace: true });
  };

  render() {
    const { getSecondaryCriteriaForSurveyRes } = this.props;
    let { questions, currentQuestion, showSubmitBtn } = this.state;

    return (
      <Box className="main-container">
        <Header
          data={{
            header: this.props.params.surveyName,
            page: PAGE_NAME.SURVEY,
            backUrl: `/app/postlogin/${PAGE_NAME.ELIGIBLE_SURVEYS}`,
          }}
        />
        {getSecondaryCriteriaForSurveyRes?.status === status.IN_PROGRESS ? (
          <div
            className="text-center d-flex align-items-center justify-content-center"
            style={{ height: "83vh" }}
          >
            <Loading />
          </div>
        ) : (
          <>
            {getSecondaryCriteriaForSurveyRes?.status === status.SUCCESS ||
            this.props.params?.token ? (
              <div className="view-questionnaires">
                <div className="survey-container">{this.renderQuestions()}</div>
                <div className="survey-buttons" justifyContent={"flex-end"}>
                  {currentQuestion > 0 && (
                    <Button
                      className="btn-primary-outlined me-3"
                      variant="outlined"
                      onClick={this.onClickPrevious}
                    >
                      Previous
                    </Button>
                  )}
                  {this.state.isDisabled ? (
                    <Button
                      style={{ width: "auto" }}
                      className="btn-primary-outlined px-5"
                      variant="outlined"
                      onClick={this.onClickBackToDashboard}
                    >
                      Back To Dashboard
                    </Button>
                  ) : (
                    <LoadingButton
                      className="btn-primary"
                      variant="contained"
                      onClick={this.onClickNext}
                      disabled={
                        this.props.saveSecondaryCriteriaRes.status ===
                        status.IN_PROGRESS
                      }
                      loading={
                        this.props.saveSecondaryCriteriaRes.status ===
                        status.IN_PROGRESS
                      }
                    >
                      {currentQuestion === questions?.length - 1 ||
                      showSubmitBtn
                        ? "Submit"
                        : "Next"}
                    </LoadingButton>
                  )}
                </div>
              </div>
            ) : null}
          </>
        )}
      </Box>
    );
  }
}

function mapStateToProps(state) {
  const { getSecondaryCriteriaForSurveyRes, saveSecondaryCriteriaRes } =
    state.survey;
  return {
    getSecondaryCriteriaForSurveyRes,
    saveSecondaryCriteriaRes,
  };
}

const mapDispatchToProps = {
  getSecondaryCriteriaForSurvey,
  saveSecondaryCriteria,
};

export default navigateRouter(
  connect(mapStateToProps, mapDispatchToProps)(EligibleSurvey)
);
