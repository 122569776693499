import React, { Component } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import Dashboard from "Postlogin/Dashboard";
import OnBoarding from "Postlogin/OnBoarding";
import FillSurvey from "Postlogin/Survey/FillSurvey";
import SurveyAvailable from "Postlogin/SurveyAvailable";
import Profile from "Postlogin/Profile";
import EligibleSurveys from "Postlogin/EligibleSurveys";
import EligibleSurvey from "Postlogin/EligibleSurveys/EligibleSurvey";
import { PAGE_NAME } from "Constants";
import ResearchDashboard from "Postlogin/ResearchProjects";
import ResearchProjectsAvailable from "Postlogin/ResearchProjects/ResearchProjectsAvailable";
import FinalizedResearchProjects from "Postlogin/ResearchProjects/FinalizedResearchProjects";
import UpdateInformation from "Postlogin/UpdateInformation";
import FillResearchProject from "Postlogin/ResearchProjects/FillResearchProject";

class AppLayout extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Routes>
        <Route
          path="*"
          element={
            <Navigate to={`/postlogin/${PAGE_NAME.DASHBOARD}`} replace={true} />
          }
        />
        <Route
          path={`/postlogin/${PAGE_NAME.DASHBOARD}`}
          element={<Dashboard />}
        />
        <Route
          path={`/postlogin/${PAGE_NAME.RESEARCH_DASHBOARD}`}
          element={<ResearchDashboard />}
        />
        <Route
          path={`/postlogin/${PAGE_NAME.RESEARCH_PROJECTS_AVAILABLE}`}
          element={<ResearchProjectsAvailable />}
        />
        <Route
          path={`/postlogin/${PAGE_NAME.FINALIZED_RESEARCH_PROJECTS}`}
          element={<FinalizedResearchProjects />}
        />
        <Route
          path="/postlogin/research-project/:id"
          element={<FillResearchProject />}
          replace={true}
          exact
        />
        <Route
          path="/postlogin/survey-preview/:surveyId/:token"
          element={<FillSurvey />}
          replace={true}
          exact
        />
        <Route
          path="/postlogin/survey/:surveyId"
          element={<FillSurvey />}
          replace={true}
          exact
        />
        <Route
          path={`/postlogin/${PAGE_NAME.ONBOARDING_QUESTION}`}
          element={<OnBoarding />}
        />
        <Route
          path={`/postlogin/${PAGE_NAME.SURVEY_AVAILABLE}`}
          element={<SurveyAvailable />}
        />
        <Route path={`/postlogin/${PAGE_NAME.PROFILE}`} element={<Profile />} />
        <Route
          path={`/postlogin/${PAGE_NAME.ELIGIBLE_SURVEYS}`}
          element={<EligibleSurveys />}
        />
        <Route
          path={`/postlogin/${PAGE_NAME.UPDATE_INFORMATION}`}
          element={<UpdateInformation />}
        />
        <Route
          path={`/postlogin/${PAGE_NAME.ELIGIBLE_SURVEYS}/:surveyId/:surveyName`}
          element={<EligibleSurvey />}
          replace={true}
          exact
        />
      </Routes>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

const connectedAppLayout = connect(mapStateToProps)(AppLayout);
export { connectedAppLayout as AppLayout };
