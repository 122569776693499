import React, { Component } from "react";
import {
  GOOGLE_ANALYTICS,
  LOCALSTORAGE_ITEMS,
  PAGE_NAME,
  status,
} from "Constants";
import { navigateRouter } from "Utils/navigateRouter";
import Header from "Postlogin/Components/Header";
import { Box, Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import EditIcon from "@mui/icons-material/Edit";
// import AvatarImg from "../../assets/img/login.png";
import {
  handleLogout,
  LocalStorageService,
  ValidationEngine,
} from "Utils/HelperFunctions";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  getUserProfile,
  updateUserProfile,
  deleteUserAccount,
} from "ReduxStore/Profile/ProfileThunk";
import { connect } from "react-redux";
import { Loading } from "Postlogin/Components/Loading";
import { alert } from "Utils/alert";
import ConfirmationPopup from "./ConfirmationPopup";
import { GA } from "Utils/ga";

import {
  Card,
  CardContent,
  CardActions,
  Button,
  OutlinedInput,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

const validationSchema = {
  firstName: [
    {
      message: "Please enter First Name",
      type: ValidationEngine.type.MANDATORY,
    },
  ],
  lastName: [
    {
      message: "Please enter Last Name",
      type: ValidationEngine.type.MANDATORY,
    },
  ],
  email: [
    {
      message: "Please enter Email",
      type: ValidationEngine.type.MANDATORY,
    },
    {
      message: "Please valid Email",
      type: ValidationEngine.type.REGEX,
      regex: ValidationEngine.EMAIL_REGEX,
    },
  ],
  mobile: [
    {
      message: "Please enter Mobile Number",
      type: ValidationEngine.type.MANDATORY,
    },
  ],
};
class PesonalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      isSubmitted: false,
      showConfirmationPopup: false,
    };

    this.userData =
      JSON.parse(LocalStorageService.getData(LOCALSTORAGE_ITEMS.userData)) ||
      {};
  }
  componentDidMount() {
    this.props.getUserProfile();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.userProfileData.status !== prevProps.userProfileData.status
    ) {
      if (this.props.userProfileData.status === status.SUCCESS) {
        let response = this.props.userProfileData.data || {};
        if (response) {
          let { firstName, lastName, email, mobile } = response;
          this.setState({ firstName, lastName, email, mobile });
        }
      } else if (this.props.userProfileData.status === status.FAILURE) {
        alert.error(
          this.props.userProfileData?.data === "Rejected"
            ? "There is some internal server error."
            : this.props.userProfileData?.data
        );
      }
    }

    if (
      this.props.updatedProfileData.status !==
      prevProps.updatedProfileData.status
    ) {
      if (this.props.updatedProfileData.status === status.SUCCESS) {
        let response = this.props.updatedProfileData.data || {};
        if (response?.status) {
          GA.dispatchGAEvent(
            GOOGLE_ANALYTICS.EVENT_CATEGORIES.RESPONDENT,
            GOOGLE_ANALYTICS.EVENT_ACTIONS.RESPONDENT_UPDATE,
            `id=${this.userData._id}`
          );
          let { firstName, lastName, email, mobile } = this.state;
          LocalStorageService.saveData(
            LOCALSTORAGE_ITEMS.userData,
            JSON.stringify({
              ...this.userData,
              firstName,
              lastName,
              email,
              mobile,
            })
          );
          this.props.navigate("/app/postlogin/dashboard");
          alert.success(response?.message);
        } else {
          alert.error(response?.message);
        }
      } else if (this.props.updatedProfileData.status === status.FAILURE) {
        alert.error(
          this.props.updatedProfileData?.data === "Rejected"
            ? "There is some internal server error."
            : this.props.updatedProfileData?.data
        );
      }
    }

    if (
      this.props.deletedUserAccountData.status !==
      prevProps.deletedUserAccountData.status
    ) {
      if (this.props.deletedUserAccountData.status === status.SUCCESS) {
        let response = this.props.deletedUserAccountData.data || {};

        if (response?.status) {
          GA.dispatchGAEvent(
            GOOGLE_ANALYTICS.EVENT_CATEGORIES.RESPONDENT,
            GOOGLE_ANALYTICS.EVENT_ACTIONS.RESPONDENT_ACCOUTN_DELETE,
            `id=${this.userData._id}`
          );
          handleLogout();
          // window.location.href = `${window.location.origin}/auth/prelogin/login`;
          this.props.navigate("/auth/prelogin/login");
          alert.success(response?.message);
        } else {
          alert.error(response?.message);
        }
      } else if (this.props.deletedUserAccountData.status === status.FAILURE) {
        alert.error(
          this.props.deletedUserAccountData?.data === "Rejected"
            ? "There is some internal server error."
            : this.props.deletedUserAccountData?.data
        );
      }
    }
  };
  validateForm = () => {
    const { firstName, lastName, email, mobile } = this.state;
    const error = ValidationEngine.validate(validationSchema, {
      firstName,
      lastName,
      email,
      mobile,
    });
    return error;
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState(
      {
        isSubmitted: true,
      },
      () => {
        const errors = this.validateForm();
        if (errors.isValid) {
          const { firstName, lastName, email, mobile } = this.state;
          this.props.updateUserProfile({
            data: { firstName, lastName, email, mobile },
          });
        }
      }
    );
  };
  handleDeleteAccount = () => {
    this.props.deleteUserAccount({
      respondentId: this.userData?._id,
    });
  };
  render() {
    const {
      firstName,
      lastName,
      email,
      mobile,
      isSubmitted,
      showConfirmationPopup,
    } = this.state;
    const errorData = this.validateForm();
    let { userProfileData, updatedProfileData, deletedUserAccountData } =
      this.props;
    return (
      <Box>
        <Card
          className="information-card border"
          style={{ minHeight: "580px" }}
        >
          <CardContent style={{ minHeight: "517px" }}>
            <div className="title border-bottom">
              <h5>Profile Details</h5>
            </div>
            <form className="w-100 mt-3" onSubmit={this.handleSubmit}>
              <div className="mb-3 form-group">
                <label for="firstName" className="form-label">
                  First Name
                </label>
                <input
                  id="firstName"
                  name="firstName"
                  value={firstName}
                  onChange={this.handleChange}
                  className="form-control"
                />{" "}
                {isSubmitted && !errorData?.firstName?.isValid ? (
                  <span style={{ color: "red" }}>
                    {errorData?.firstName?.message}
                  </span>
                ) : (
                  <></>
                )}
              </div>
              <div className="mb-3 form-group">
                <label for="lastName" className="form-label">
                  Last Name
                </label>
                <input
                  id="lastName"
                  className="form-control"
                  name="lastName"
                  value={lastName}
                  onChange={this.handleChange}
                />
                {isSubmitted && !errorData?.lastName?.isValid ? (
                  <span style={{ color: "red" }}>
                    {errorData?.lastName?.message}
                  </span>
                ) : (
                  <></>
                )}
              </div>
              <div className="mb-3 form-group">
                <label for="email" className="form-label">
                  Email
                </label>
                <input
                  id="email"
                  className="form-control"
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                />
                {isSubmitted && !errorData?.email?.isValid ? (
                  <span style={{ color: "red" }}>
                    {errorData?.email?.message}
                  </span>
                ) : (
                  <></>
                )}
              </div>
              <div className="mb-3 form-group">
                <label for="mobile" className="form-label">
                  Mobile
                </label>
                <input
                  id="mobile"
                  className="form-control"
                  name="mobile"
                  value={mobile}
                  onChange={this.handleChange}
                />
                {isSubmitted && !errorData?.mobile?.isValid ? (
                  <span style={{ color: "red" }}>
                    {errorData?.mobile?.message}
                  </span>
                ) : (
                  <></>
                )}
              </div>
            </form>
          </CardContent>
          <CardActions className="buttons px-3 pb-3">
            <LoadingButton
              className="btn btn-primary rounded"
              variant="contained"
              loading={updatedProfileData.status === status.IN_PROGRESS}
              disabled={updatedProfileData.status === status.IN_PROGRESS}
              type="submit"
              onClick={this.handleSubmit}
            >
              {updatedProfileData.status !== status.IN_PROGRESS && "Update"}
            </LoadingButton>
            <LoadingButton
              variant="outlined"
              color="error"
              className="btn-primary-error rounded"
              onClick={() => {
                this.setState({ showConfirmationPopup: true });
              }}
            >
              Delete Account
            </LoadingButton>
          </CardActions>
        </Card>

        {showConfirmationPopup ? (
          <ConfirmationPopup
            show={showConfirmationPopup}
            handleClose={() => {
              this.setState({ showConfirmationPopup: false });
            }}
            isLoading={deletedUserAccountData.status === status.IN_PROGRESS}
            handlSubmit={() => {
              this.handleDeleteAccount();
            }}
          />
        ) : (
          <></>
        )}
      </Box>
    );
  }
}

function mapStateToProps(state) {
  const { userProfileData, updatedProfileData, deletedUserAccountData } =
    state.profile;
  return {
    userProfileData,
    updatedProfileData,
    deletedUserAccountData,
  };
}

const mapDispatchToProps = {
  getUserProfile,
  updateUserProfile,
  deleteUserAccount,
};

const connectedProfile = connect(
  mapStateToProps,
  mapDispatchToProps
)(PesonalDetails);

export default navigateRouter(connectedProfile);
