import React, { Component } from "react";
import { Box } from "@mui/material";
import { PAGE_NAME, status } from "Constants";
import Header from "Postlogin/Components/Header";
import { Card } from "@mui/material";
import { Link } from "react-router-dom";
import { getDashboardDetailsForResearchProject } from "ReduxStore/researchProject/researchProjectThunk";
import { connect } from "react-redux";

class ResearchDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardData: null,
    };
  }

  componentDidMount = () => {
    this.props.getDashboardDetailsForResearchProject();
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.getDashboardDetailsForResearchProjectRes.status !==
        this.props.getDashboardDetailsForResearchProjectRes.status &&
      this.props.getDashboardDetailsForResearchProjectRes.status ===
        status.SUCCESS
    ) {
      this.setState({
        dashboardData: this.props.getDashboardDetailsForResearchProjectRes.data,
      });
    }
  };

  render() {
    const { dashboardData } = this.state;
    return (
      <Box className="main-container">
        <Header
          data={{
            header: "Research Dashboard",
            page: PAGE_NAME.RESEARCH_DASHBOARD,
            backUrl: ``,
          }}
        />
        <div className="researchProjects-container">
          <div className="survey-cards ">
            <div className="row g-3 align-items-center">
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <Link to="/app/postlogin/research-projects-available">
                  <Card className={`survey-card ${
                      dashboardData?.totalElligibleResearchProject
                        ? "survey-Onboarding-card"
                        : ""
                    }  p-3`}>
                       {dashboardData?.totalElligibleResearchProject ? (
                      <svg
                        height="100%"
                        width="100%"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          rx="8"
                          ry="8"
                          className="line"
                          height="100%"
                          width="100%"
                        />
                      </svg>
                    ) : (
                      <></>
                    )}
                    <h5 className="survey-title">Eligible Research Projects</h5>
                    <div className="survey-number">
                      {dashboardData?.totalElligibleResearchProject}
                    </div>
                  </Card>
                </Link>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <Link to="/app/postlogin/finalized-research-projects">
                  <Card className={`survey-card ${
                      dashboardData?.totalFinalizedResearchProject
                        ? "survey-Onboarding-card"
                        : ""
                    }  p-3`} >
                       {dashboardData?.totalFinalizedResearchProject ? (
                      <svg
                        height="100%"
                        width="100%"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          rx="8"
                          ry="8"
                          className="line"
                          height="100%"
                          width="100%"
                        />
                      </svg>
                    ) : (
                      <></>
                    )}
                    <h5 className="survey-title">
                      Finalized Research Projects
                    </h5>
                    <div className="survey-number">
                      {dashboardData?.totalFinalizedResearchProject}
                    </div>
                  </Card>
                </Link>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <Link to="#">
                  <Card className="survey-card p-3">
                    <h5 className="survey-title">Total Rewards Earned</h5>
                    <div className="survey-number">0</div>
                  </Card>
                </Link>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <Link to="#">
                  <Card className="survey-card p-3">
                    <h5 className="survey-title">
                      Total projects you completed
                    </h5>
                    <div className="survey-number">
                      {dashboardData?.totalResearchProjectDone}
                    </div>
                  </Card>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  const { getDashboardDetailsForResearchProjectRes } = state.researchProject;
  return { getDashboardDetailsForResearchProjectRes };
};

const mapDispatchToProps = {
  getDashboardDetailsForResearchProject,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResearchDashboard);
