export const LOCALSTORAGE_ITEMS = {
  userData: "userData",
  authToken: "authToken",
};

export const status = {
  IN_PROGRESS: 0,
  SUCCESS: 1,
  FAILURE: 2,
};

export const componentType = {
  DESCRIPTION: "desc",
  MULTIPLE_CHOICE: "multiChoice",
  FREE_TEXT: "freeTxt",
  SINGLE_CHOICE: "singleChoice",
  OPINION: "opinion",
  RATE: "rating",
  GRID: "grid",
  LINESCALE: "lineScale",
  HEDONICSCALE: "hedonicScale",
  JAR: "JAR",
  IMAGE_UPLOAD: "IMAGE_UPLOAD",
  SELECT: "select",
  MULTIPLE_SELECT: "multiSelect",
  OUTRO: "OUTRO",
  WELCOME: "WELCOME",
};

export const eligibleSurveyComponentTypes = {
  MULTIPLE_CHOICE: "MULTIPLE_CHOICE",
  SINGLE_CHOICE: "SINGLE_CHOICE",
};

export const PAGE_NAME = {
  DASHBOARD: "dashboard",
  RESEARCH_DASHBOARD: "research-dashboard",
  RESEARCH_PROJECTS_AVAILABLE: "research-projects-available",
  FINALIZED_RESEARCH_PROJECTS: "finalized-research-projects",
  SURVEY_AVAILABLE: "surveyavailable",
  ELIGIBLE_SURVEYS: "eligible-surveys",
  ONBOARDING_QUESTION: "onboarding",
  SURVEY: "survey",
  PROFILE: "profile",
  CHANGE_PASSWORD: "change-password",
  TERMS_AND_CONDITIONS: "terms-and-conditions",
  BANK_DETAILS: "bank-details",
  UPDATE_INFORMATION: "update-information",
};

export const ORGANIZATION_PORTAL_URL = `http://localhost:4002`;

export const COMPARATOR_TYPES = {
  IS_ANSWERED: {
    label: "is answered",
    value: "0",
  },
  IS_NOT_ANSWERED: {
    label: "is not answered",
    value: "1",
  },

  // IS_SELECTED: {
  //   label: "is selected",
  //   value: "2",
  // },
  CONTAINS: {
    label: "contains",
    value: "3",
  },
  DOES_NOT_CONTAIN: {
    label: "does not contain",
    value: "4",
  },
  EQUALS: {
    label: "equals",
    value: "5",
  },
  IS_NOT_EQUAL_TO: {
    label: "is not equal to",
    value: "6",
  },
  STARTS_WITH: {
    label: "starts with",
    value: "7",
  },
  ENDS_WITH: {
    label: "ends with",
    value: "8",
  },
  SELECTS: {
    label: "selects",
    value: "9",
  },
  NOT_SELECT: {
    label: "does not select",
    value: "10",
  },
  IN_BETWEEN: {
    label: "is in between",
    value: "11",
  },
  IS_NOT_IN_BETWEEN: {
    label: "is not in between",
    value: "12",
  },
};

export const COMPARATORS = {
  [componentType.FREE_TEXT]: [
    COMPARATOR_TYPES.EQUALS,
    COMPARATOR_TYPES.IS_NOT_EQUAL_TO,
    COMPARATOR_TYPES.CONTAINS,
    COMPARATOR_TYPES.DOES_NOT_CONTAIN,
    COMPARATOR_TYPES.STARTS_WITH,
    COMPARATOR_TYPES.ENDS_WITH,
    COMPARATOR_TYPES.IS_ANSWERED,
    COMPARATOR_TYPES.IS_NOT_ANSWERED,
  ],
  [componentType.MULTIPLE_CHOICE]: [
    COMPARATOR_TYPES.IS_ANSWERED,
    COMPARATOR_TYPES.IS_NOT_ANSWERED,
    COMPARATOR_TYPES.SELECTS,
    COMPARATOR_TYPES.NOT_SELECT,
  ],
  [componentType.SINGLE_CHOICE]: [
    COMPARATOR_TYPES.IS_ANSWERED,
    COMPARATOR_TYPES.IS_NOT_ANSWERED,
    COMPARATOR_TYPES.SELECTS,
    COMPARATOR_TYPES.NOT_SELECT,
  ],
  [componentType.RATE]: [
    COMPARATOR_TYPES.IS_ANSWERED,
    COMPARATOR_TYPES.IS_NOT_ANSWERED,
    COMPARATOR_TYPES.EQUALS,
    COMPARATOR_TYPES.IS_NOT_EQUAL_TO,
    COMPARATOR_TYPES.IS_NOT_IN_BETWEEN,
    COMPARATOR_TYPES.IN_BETWEEN,
  ],
  [componentType.GRID]: [
    COMPARATOR_TYPES.IS_ANSWERED,
    COMPARATOR_TYPES.IS_NOT_ANSWERED,
  ],
  [componentType.LINESCALE]: [
    COMPARATOR_TYPES.IS_ANSWERED,
    COMPARATOR_TYPES.IS_NOT_ANSWERED,
    COMPARATOR_TYPES.EQUALS,
    COMPARATOR_TYPES.IS_NOT_EQUAL_TO,
    COMPARATOR_TYPES.IS_NOT_IN_BETWEEN,
    COMPARATOR_TYPES.IN_BETWEEN,
  ],
};

export const JOINS = [
  {
    label: "and",
    value: 0,
  },
  {
    label: "or",
    value: 1,
  },
];

export const SURVEY_CONSTANT = {};

SURVEY_CONSTANT.STATUS = {
  DRAFT: "DRAFT",
  PUBLISH: "PUBLISH",
  UNPUBLISHED: "UNPUBLISHED",
};

SURVEY_CONSTANT.ONBOARDING_QUESTION_TYPE = {
  SINGLE_CHOICE: "SINGLE_CHOICE",
  MULTIPLE_CHOICE: "MULTIPLE_CHOICE",
  INPUT_BOX: "INPUT_BOX",
  DATE: "DATE",
  SELECT: "SELECT",
  MULTI_SELECT: "MULTI_SELECT",
};

SURVEY_CONSTANT.ANSWER_TYPE = {
  NUMBER: "NUMBER",
  STRING: "STRING",
  RANGE: "RANGE",
  DATE: "DATE",
  ARRAY: "ARRAY",
};

export const RESEARCH_PROJECT_CONSTANT = {};

RESEARCH_PROJECT_CONSTANT.STATUS = {
  DRAFT: "DRAFT",
  PUBLISHED: "PUBLISHED",
  UNPUBLISHED: "UNPUBLISHED",
  COMPLETED: "COMPLETED",
};

RESEARCH_PROJECT_CONSTANT.SCREENING_QUESTION_TYPE = {
  MULTIPLE_CHOICE: "MULTIPLE_CHOICE",
  FREE_TEXT: "FREE_TEXT",
  SINGLE_CHOICE: "SINGLE_CHOICE",
  SINGLE_SELECT: "SINGLE_SELECT",
  MULTI_SELECT: "MULTI_SELECT",
};

export const FCM_TOKEN = "fcmToken";

export const GOOGLE_ANALYTICS = {};

GOOGLE_ANALYTICS.MEASURMENT_ID = "G-YVPWJTCNMX";

GOOGLE_ANALYTICS.EVENT_ACTIONS = {
  LOGIN: "Login",
  REGISTER: "Register",
  ONBOARDING_ANSWER_SUBMIT: "Onboarding Answer Submit",
  RESPONDENT_UPDATE: "Respondent Update",
  RESPONDENT_ACCOUTN_DELETE: "Respondent Account Delete",
  BANK_DETAILS_ADD: "Bank details added",
  RESPONDENT_CHANGE_PASSWORD: "Respondent change password",
  RESPONDENT_SURVEY_SUBMIT: "Respondent survey submit",
};

GOOGLE_ANALYTICS.EVENT_CATEGORIES = {
  RESPONDENT: "Respondent",
};
