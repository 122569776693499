import React, { Component } from "react";
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Button,
  TextField,
  Autocomplete,
  FormControl,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { PAGE_NAME, status } from "Constants";
import Header from "Postlogin/Components/Header";
import { LoadingButton } from "@mui/lab";
import {
  getAllMeta,
  saveMetaForRespondent,
  getMetaForRespondent,
} from "ReduxStore/researchProject/researchProjectThunk";
import { connect } from "react-redux";
import { alert } from "Utils/alert";

class UpdateInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      industries: [],
      employmentStatus: [],
      companySize: [],
      jobTitles: [],
      jobFunctions: [],
      seniorityLevels: [],
      skills: [],
      selectedIndustry: "",
      selectedEmploymentStatus: "",
      selectedCompanySize: "",
      selectedJobTitle: "",
      selectedJobFunction: "",
      selectedSeniorityLevel: "",
      selectedSkill: "",
    };
  }
  componentDidMount = () => {
    this.props.getAllMeta();
    this.props.getMetaForRespondent();
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.getAllMetaRes.status !== this.props.getAllMetaRes.status &&
      this.props.getAllMetaRes.status === status.SUCCESS
    ) {
      const metaData = this.props.getAllMetaRes.data;
      const {
        companySize,
        employmentStatus,
        jobFunctions,
        inddustries,
        jobTitles,
        seniorityLevels,
        skills,
      } = metaData;
      this.setState({
        industries: inddustries,
        employmentStatus,
        jobFunctions,
        seniorityLevels,
        companySize,
        skills,
        jobTitles,
      });
    }

    if (
      prevProps.saveMetaForRespondentRes.status !==
        this.props.saveMetaForRespondentRes.status &&
      this.props.saveMetaForRespondentRes.status === status.SUCCESS
    ) {
      alert.success("Meta saved successfully!");
      this.props.getMetaForRespondent();
    } else if (
      prevProps.saveMetaForRespondentRes.status !==
        this.props.saveMetaForRespondentRes.status &&
      this.props.saveMetaForRespondentRes.status === status.FAILURE
    ) {
      alert.error("There is some error!");
    }

    if (
      prevProps.getMetaForRespondentRes.status !==
        this.props.getMetaForRespondentRes.status &&
      this.props.getMetaForRespondentRes.status === status.SUCCESS
    ) {
      if (this.props.getMetaForRespondentRes.data.length) {
        const apiData = this.props.getMetaForRespondentRes.data[0];
        const {
          companySize,
          employmentStatus,
          industry,
          jobFunction,
          jobTitle,
          seniorityLevel,
          skill,
        } = apiData;
        this.setState({
          selectedCompanySize: companySize,
          selectedEmploymentStatus: employmentStatus,
          selectedIndustry: industry,
          selectedJobFunction: jobFunction,
          selectedJobTitle: jobTitle,
          selectedSeniorityLevel: seniorityLevel,
          selectedSkill: skill,
        });
      }
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = () => {
    const {
      selectedIndustry,
      selectedEmploymentStatus,
      selectedCompanySize,
      selectedJobFunction,
      selectedJobTitle,
      selectedSeniorityLevel,
      selectedSkill,
    } = this.state;
    if (
      !selectedIndustry ||
      !selectedEmploymentStatus ||
      !selectedCompanySize ||
      !selectedJobFunction ||
      !selectedJobTitle ||
      !selectedSeniorityLevel ||
      !selectedSkill
    ) {
      alert.error("All fields are required!");
    } else {
      const formData = {
        companySize: selectedCompanySize,
        employmentStatus: selectedEmploymentStatus,
        industry: selectedIndustry,
        jobFunction: selectedJobFunction,
        jobTitle: selectedJobTitle,
        seniorityLevel: selectedSeniorityLevel,
        skill: selectedSkill,
      };
      this.props.saveMetaForRespondent(formData);
    }
  };

  render() {
    const {
      industries,
      employmentStatus,
      companySize,
      jobTitles,
      jobFunctions,
      seniorityLevels,
      skills,
      selectedIndustry,
      selectedEmploymentStatus,
      selectedCompanySize,
      selectedJobFunction,
      selectedJobTitle,
      selectedSeniorityLevel,
      selectedSkill,
    } = this.state;
    return (
      <Box className="main-container">
        <Header
          data={{
            header: "Update Information",
            page: PAGE_NAME.UPDATE_INFORMATION,
            backUrl: ``,
          }}
        />
        <Box className="update-information-container">
          <Grid container spacing={3}>
            <Grid size={{ xs: 12, md: 6, sm: 6, lg: 4 }}>
              <Card className="information-card border">
                <CardContent>
                  <div className="title border-bottom">
                    <h5>Employment Status</h5>
                  </div>
                  <form className="w-100 mt-3">
                    <FormControl size="small" className="d-block w-100 mb-3 form-group">
                      <label for="industries" className="form-label">
                        Industries
                      </label>
                      <Autocomplete
                         className="form-control autocomplete-box px-0"
                         size="small"
                        value={
                          industries.find(
                            (item) => item._id === selectedIndustry
                          ) || null
                        }
                        onChange={(event, newValue) => {
                          this.setState({
                            selectedIndustry: newValue ? newValue._id : null,
                          });
                        }}
                        options={industries}
                        getOptionLabel={(option) => option.industry}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </FormControl>
                    <FormControl size="small" className="d-block w-100 mb-3 form-group">
                      <label for="firstName" className="form-label">
                        Employment Status
                      </label>
                      <Autocomplete
                       className="form-control autocomplete-box px-0"
                         size="small"
                        value={
                          employmentStatus.find(
                            (item) => item._id === selectedEmploymentStatus
                          ) || null
                        }
                        onChange={(event, newValue) => {
                          this.setState({
                            selectedEmploymentStatus: newValue
                              ? newValue._id
                              : null,
                          });
                        }}
                        options={employmentStatus}
                        getOptionLabel={(option) => option.employmentStatus}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </FormControl>
                    <FormControl size="small" className="d-block w-100 mb-3 form-group">
                      <label for="firstName" className="form-label">
                        Company Size
                      </label>
                      <Autocomplete   className="form-control autocomplete-box px-0"
                         size="small"
                        value={
                          companySize.find(
                            (item) => item._id === selectedCompanySize
                          ) || null
                        }
                        onChange={(event, newValue) => {
                          this.setState({
                            selectedCompanySize: newValue ? newValue._id : null,
                          });
                        }}
                        options={companySize}
                        getOptionLabel={(option) => option.companySize}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </FormControl>
                    <FormControl size="small" className="d-block w-100 mb-3 form-group">
                      <label for="firstName" className="form-label">
                        Job Title
                      </label>
                      <Autocomplete  className="form-control autocomplete-box px-0"
                         size="small"
                        value={
                          jobTitles.find(
                            (item) => item._id === selectedJobTitle
                          ) || null
                        }
                        onChange={(event, newValue) => {
                          this.setState({
                            selectedJobTitle: newValue ? newValue._id : null,
                          });
                        }}
                        options={jobTitles}
                        getOptionLabel={(option) => option.jobTitle}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </FormControl>
                    <FormControl size="small" className="d-block w-100 mb-3 form-group">
                      <label for="firstName" className="form-label">
                        Job Function
                      </label>
                      <Autocomplete  className="form-control autocomplete-box px-0"
                         size="small"
                        value={
                          jobFunctions.find(
                            (item) => item._id === selectedJobFunction
                          ) || null
                        }
                        onChange={(event, newValue) => {
                          this.setState({
                            selectedJobFunction: newValue ? newValue._id : null,
                          });
                        }}
                        options={jobFunctions}
                        getOptionLabel={(option) => option.jobFunction}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </FormControl>
                    <FormControl size="small" className="d-block w-100 mb-3 form-group">
                      <label for="firstName" className="form-label">
                        Seniority Level
                      </label>
                      <Autocomplete className="form-control autocomplete-box px-0"
                         size="small"
                        value={
                          seniorityLevels.find(
                            (item) => item._id === selectedSeniorityLevel
                          ) || null
                        }
                        onChange={(event, newValue) => {
                          this.setState({
                            selectedSeniorityLevel: newValue
                              ? newValue._id
                              : null,
                          });
                        }}
                        options={seniorityLevels}
                        getOptionLabel={(option) => option.seniorityLevel}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </FormControl>
                    <FormControl size="small" className="d-block w-100 mb-3 form-group">
                      <label for="firstName" className="form-label">
                        Skills
                      </label>
                      <Autocomplete className="form-control autocomplete-box px-0"
                         size="small"
                        value={
                          skills.find((item) => item._id === selectedSkill) ||
                          null
                        }
                        onChange={(event, newValue) => {
                          this.setState({
                            selectedSkill: newValue ? newValue._id : null,
                          });
                        }}
                        options={skills}
                        getOptionLabel={(option) => option.skill}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </FormControl>
                  </form>
                </CardContent>
                <CardActions
                  className="px-3 pb-3"
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    size="small"
                    variant="outlined"
                    className="btn-primary-outlined w-50 rounded px-3"
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    size="small"
                    variant="contained"
                    className="btn btn-primary rounded w-50 px-3"
                    onClick={this.handleSubmit}
                    loading={
                      this.props.saveMetaForRespondentRes.status ===
                      status.IN_PROGRESS
                    }
                  >
                    Submit
                  </LoadingButton>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  const { getAllMetaRes, saveMetaForRespondentRes, getMetaForRespondentRes } =
    state.researchProject;
  return {
    getAllMetaRes,
    saveMetaForRespondentRes,
    getMetaForRespondentRes,
  };
};

const mapDispatchToProps = {
  getAllMeta,
  saveMetaForRespondent,
  getMetaForRespondent,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateInformation);
