import { createSlice } from "@reduxjs/toolkit";
import { status } from "Constants";
import {
  getAllMeta,
  saveMetaForRespondent,
  getMetaForRespondent,
  getProspectResearchProjectsForRespondent,
  getResearchProjectDetailsById,
  saveResearchProjectScreeningQuestion,
  getDashboardDetailsForResearchProject,
  getFinalizedResearchProjectForRespondent,
} from "./researchProjectThunk";

const researchProjectSlice = createSlice({
  name: "ResearchMeta",
  initialState: {
    getAllMetaRes: {
      status: null,
      data: {},
    },
    saveMetaForRespondentRes: {
      status: null,
      data: {},
    },
    getMetaForRespondentRes: {
      status: null,
      data: {},
    },
    getProspectResearchProjectsForRespondentRes: {
      status: null,
      data: {},
    },
    getResearchProjectDetailsByIdRes: {
      status: null,
      data: {},
    },
    saveResearchProjectScreeningQuestionRes: {
      status: null,
      data: {},
    },
    getDashboardDetailsForResearchProjectRes: {
      status: null,
      data: {},
    },
    getFinalizedResearchProjectForRespondentRes: {
      status: null,
      data: {},
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllMeta.pending.toString(), (state, action) => {
        return {
          ...state,
          getAllMetaRes: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(getAllMeta.fulfilled.toString(), (state, { payload }) => {
        return {
          ...state,
          getAllMetaRes: {
            status: status.SUCCESS,
            data: payload.data,
          },
        };
      })
      .addCase(getAllMeta.rejected.toString(), (state, { error }) => {
        return {
          ...state,
          getAllMetaRes: {
            status: status.FAILURE,
            data: error?.message,
          },
        };
      })

      .addCase(saveMetaForRespondent.pending.toString(), (state, action) => {
        return {
          ...state,
          saveMetaForRespondentRes: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(
        saveMetaForRespondent.fulfilled.toString(),
        (state, { payload }) => {
          return {
            ...state,
            saveMetaForRespondentRes: {
              status: status.SUCCESS,
              data: payload.data,
            },
          };
        }
      )
      .addCase(
        saveMetaForRespondent.rejected.toString(),
        (state, { error }) => {
          return {
            ...state,
            saveMetaForRespondentRes: {
              status: status.FAILURE,
              data: error?.message,
            },
          };
        }
      )

      .addCase(getMetaForRespondent.pending.toString(), (state, action) => {
        return {
          ...state,
          getMetaForRespondentRes: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(
        getMetaForRespondent.fulfilled.toString(),
        (state, { payload }) => {
          return {
            ...state,
            getMetaForRespondentRes: {
              status: status.SUCCESS,
              data: payload.data,
            },
          };
        }
      )
      .addCase(getMetaForRespondent.rejected.toString(), (state, { error }) => {
        return {
          ...state,
          getMetaForRespondentRes: {
            status: status.FAILURE,
            data: error?.message,
          },
        };
      })

      .addCase(
        getProspectResearchProjectsForRespondent.pending.toString(),
        (state, action) => {
          return {
            ...state,
            getProspectResearchProjectsForRespondentRes: {
              status: status.IN_PROGRESS,
            },
          };
        }
      )
      .addCase(
        getProspectResearchProjectsForRespondent.fulfilled.toString(),
        (state, { payload }) => {
          return {
            ...state,
            getProspectResearchProjectsForRespondentRes: {
              status: status.SUCCESS,
              data: payload,
            },
          };
        }
      )
      .addCase(
        getProspectResearchProjectsForRespondent.rejected.toString(),
        (state, { error }) => {
          return {
            ...state,
            getProspectResearchProjectsForRespondentRes: {
              status: status.FAILURE,
              data: error?.message,
            },
          };
        }
      )

      .addCase(
        getResearchProjectDetailsById.pending.toString(),
        (state, action) => {
          return {
            ...state,
            getResearchProjectDetailsByIdRes: {
              status: status.IN_PROGRESS,
            },
          };
        }
      )
      .addCase(
        getResearchProjectDetailsById.fulfilled.toString(),
        (state, { payload }) => {
          return {
            ...state,
            getResearchProjectDetailsByIdRes: {
              status: status.SUCCESS,
              data: payload,
            },
          };
        }
      )
      .addCase(
        getResearchProjectDetailsById.rejected.toString(),
        (state, { error }) => {
          return {
            ...state,
            getResearchProjectDetailsByIdRes: {
              status: status.FAILURE,
              data: error?.message,
            },
          };
        }
      )

      .addCase(
        saveResearchProjectScreeningQuestion.pending.toString(),
        (state, action) => {
          return {
            ...state,
            saveResearchProjectScreeningQuestionRes: {
              status: status.IN_PROGRESS,
            },
          };
        }
      )
      .addCase(
        saveResearchProjectScreeningQuestion.fulfilled.toString(),
        (state, { payload }) => {
          return {
            ...state,
            saveResearchProjectScreeningQuestionRes: {
              status: status.SUCCESS,
              data: payload,
            },
          };
        }
      )
      .addCase(
        saveResearchProjectScreeningQuestion.rejected.toString(),
        (state, { error }) => {
          return {
            ...state,
            saveResearchProjectScreeningQuestionRes: {
              status: status.FAILURE,
              data: error?.message,
            },
          };
        }
      )

      .addCase(
        getDashboardDetailsForResearchProject.pending.toString(),
        (state, action) => {
          return {
            ...state,
            getDashboardDetailsForResearchProjectRes: {
              status: status.IN_PROGRESS,
            },
          };
        }
      )
      .addCase(
        getDashboardDetailsForResearchProject.fulfilled.toString(),
        (state, { payload }) => {
          return {
            ...state,
            getDashboardDetailsForResearchProjectRes: {
              status: status.SUCCESS,
              data: payload,
            },
          };
        }
      )
      .addCase(
        getDashboardDetailsForResearchProject.rejected.toString(),
        (state, { error }) => {
          return {
            ...state,
            getDashboardDetailsForResearchProjectRes: {
              status: status.FAILURE,
              data: error?.message,
            },
          };
        }
      )

      .addCase(
        getFinalizedResearchProjectForRespondent.pending.toString(),
        (state, action) => {
          return {
            ...state,
            getFinalizedResearchProjectForRespondentRes: {
              status: status.IN_PROGRESS,
            },
          };
        }
      )
      .addCase(
        getFinalizedResearchProjectForRespondent.fulfilled.toString(),
        (state, { payload }) => {
          return {
            ...state,
            getFinalizedResearchProjectForRespondentRes: {
              status: status.SUCCESS,
              data: payload,
            },
          };
        }
      )
      .addCase(
        getFinalizedResearchProjectForRespondent.rejected.toString(),
        (state, { error }) => {
          return {
            ...state,
            getFinalizedResearchProjectForRespondentRes: {
              status: status.FAILURE,
              data: error?.message,
            },
          };
        }
      );
  },
});

export default researchProjectSlice.reducer;
