import React, { Component } from "react";
import { Box, Modal, Typography, Button, Card } from "@mui/material";
import { PAGE_NAME, status, LOCALSTORAGE_ITEMS } from "Constants";
import Header from "Postlogin/Components/Header";
import { navigateRouter } from "Utils/navigateRouter";
import { Loading } from "Postlogin/Components/Loading";
import { getFinalizedResearchProjectForRespondent } from "ReduxStore/researchProject/researchProjectThunk";
import { connect } from "react-redux";
import { LocalStorageService } from "Utils/HelperFunctions";
import { Link } from "react-router-dom";

class FinalizedResearchProjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      finalizedResearchProjects: [],
      isModalOpen: false,
    };
  }

  componentDidMount = () => {
    let userData = JSON.parse(
      LocalStorageService.getData(LOCALSTORAGE_ITEMS.userData)
    );
    this.props.getFinalizedResearchProjectForRespondent(userData._id);
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.getFinalizedResearchProjectForRespondentRes.status !==
        this.props.getFinalizedResearchProjectForRespondentRes.status &&
      this.props.getFinalizedResearchProjectForRespondentRes.status ===
        status.SUCCESS
    ) {
      this.setState({
        finalizedResearchProjects:
          this.props.getFinalizedResearchProjectForRespondentRes.data
            .ProjectDetails,
      });
    }
  };

  toggleModal = () => {
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));
  };

  render() {
    const { finalizedResearchProjects, isModalOpen } = this.state;
    const { getFinalizedResearchProjectForRespondentRes } = this.props;
    return (
      <Box className="main-container">
        <Header
          data={{
            header: "Finalized Research Projects",
            page: PAGE_NAME.RESEARCH_PROJECTS_AVAILABLE,
            backUrl: `/app/postlogin/${PAGE_NAME.RESEARCH_DASHBOARD}`,
          }}
        />
        <div className="d-block">
          {getFinalizedResearchProjectForRespondentRes.status ===
          status.IN_PROGRESS ? (
            <div
              className="text-center d-flex align-items-center justify-content-center"
              style={{ height: "83vh" }}
            >
              <Loading />
            </div>
          ) : finalizedResearchProjects.length ? (
            <div className="researchProjects-container">
              <div className="finalized-cards">
                <div className="row g-3 align-items-center">
                  {finalizedResearchProjects.map((researchProject, index) => {
                    return (
                      <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                        <Link to="">
                          <Card
                            className="finalized-card p-3"
                            key={index}
                            onClick={this.toggleModal}
                          >
                            <div className="d-block mb-2 w-100">
                              <h5 className="finalized-title d-block w-100">
                                {researchProject.projectId.title}
                              </h5>
                              <span className="finalized-description d-block w-100">
                                {researchProject.projectId.description}
                              </span>
                            </div>

                            <p className="d-block w-100">
                              <strong>Incentive : </strong> &#8377;
                              {researchProject.projectId.insentive}
                            </p>
                            <p className="d-block w-100">
                              <strong>Time Required : </strong>
                              {researchProject.projectId.timeRequired} Minutes
                            </p>
                          </Card>
                        </Link>
                      </div>
                    );
                  })}{" "}
                </div>
              </div>
            </div>
          ) : (
            <div
              className="text-center d-flex align-items-center justify-content-center"
              style={{ height: "83vh" }}
            >
              <h4 className="description">
                {getFinalizedResearchProjectForRespondentRes.status ===
                status.FAILURE
                  ? getFinalizedResearchProjectForRespondentRes?.data ===
                    "Rejected"
                    ? "There is some internal server error."
                    : getFinalizedResearchProjectForRespondentRes?.data
                  : "No research projects found."}
              </h4>
            </div>
          )}
        </div>

        {/* Modal Component */}
        <Modal
          open={isModalOpen}
          onClose={this.toggleModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <p className="modal-description">
              The organization will contact you via email or phone call.
            </p>
            <Box className="d-block w-100">
              <Button
              size="small"
                style={{ float: "inline-end" }}
                className="btn btn-primary rounded"
                variant="contained"
                onClick={this.toggleModal}
                sx={{ mt: 2 }}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  const { getFinalizedResearchProjectForRespondentRes } = state.researchProject;
  return { getFinalizedResearchProjectForRespondentRes };
};

const mapDispatchToProps = {
  getFinalizedResearchProjectForRespondent,
};

export default navigateRouter(
  connect(mapStateToProps, mapDispatchToProps)(FinalizedResearchProjects)
);
