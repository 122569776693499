import React, { Component } from "react";
import {
  GOOGLE_ANALYTICS,
  LOCALSTORAGE_ITEMS,
  PAGE_NAME,
  status,
} from "Constants";
import { navigateRouter } from "Utils/navigateRouter";
import Header from "Postlogin/Components/Header";
import { Box, Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import EditIcon from "@mui/icons-material/Edit";
import AvatarImg from "../../assets/img/login.png";
import {
  handleLogout,
  LocalStorageService,
  ValidationEngine,
} from "Utils/HelperFunctions";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  getUserProfile,
  updateUserProfile,
  deleteUserAccount,
} from "ReduxStore/Profile/ProfileThunk";
import { connect } from "react-redux";
import { Loading } from "Postlogin/Components/Loading";
import { alert } from "Utils/alert";
import ConfirmationPopup from "./Components/ConfirmationPopup";
import { GA } from "Utils/ga";

import {
  Card,
  CardContent,
  CardActions,
  Button,
  OutlinedInput,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import PersonalDetails from "./Components/PersonalDetails";
import BankDetails from "./Components/BankDetails";
import ChangePassword from "./Components/ChangePassword";
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Box className="main-container">
        <Header
          data={{
            header: "Profile",
            page: PAGE_NAME.PROFILE,
            backUrl: `/app/postlogin/${PAGE_NAME.DASHBOARD}`,
          }}
        />
        <div className="profile-container ">
          <Grid container spacing={3}>
            <Grid size={{ xs: 12, md: 6, sm: 6, lg: 4 }}>
              <PersonalDetails />
            </Grid>
            <Grid size={{ xs: 12, md: 6, sm: 6, lg: 4 }}>
              <BankDetails />
            </Grid>
            <Grid size={{ xs: 12, md: 6, sm: 6, lg: 4 }}>
              <ChangePassword />
            </Grid>
          </Grid>
        </div>
      </Box>
    );
  }
}

function mapStateToProps(state) {
  const { userProfileData, updatedProfileData, deletedUserAccountData } =
    state.profile;
  return {
    userProfileData,
    updatedProfileData,
    deletedUserAccountData,
  };
}

const mapDispatchToProps = {
  getUserProfile,
  updateUserProfile,
  deleteUserAccount,
};

const connectedProfile = connect(mapStateToProps, mapDispatchToProps)(Profile);

export default navigateRouter(connectedProfile);
