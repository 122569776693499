import React, { Component } from "react";
import { Box, Card } from "@mui/material";
import { PAGE_NAME, status } from "Constants";
import Header from "Postlogin/Components/Header";
import { navigateRouter } from "Utils/navigateRouter";
import { Loading } from "Postlogin/Components/Loading";
import { getProspectResearchProjectsForRespondent } from "ReduxStore/researchProject/researchProjectThunk";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class ResearchProjectsAvailable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      researchProjects: [],
    };
  }

  componentDidMount = () => {
    this.props.getProspectResearchProjectsForRespondent();
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.getProspectResearchProjectsForRespondentRes.status !==
        this.props.getProspectResearchProjectsForRespondentRes.status &&
      this.props.getProspectResearchProjectsForRespondentRes.status ===
        status.SUCCESS
    ) {
      this.setState({
        researchProjects:
          this.props.getProspectResearchProjectsForRespondentRes.data
            .ProjectDetails,
      });
    }
  };

  render() {
    const { researchProjects } = this.state;
    const { getProspectResearchProjectsForRespondentRes } = this.props;
    return (
      <Box className="main-container">
        <Header
          data={{
            header: "Eligible Research Projects",
            page: PAGE_NAME.RESEARCH_PROJECTS_AVAILABLE,
            backUrl: `/app/postlogin/${PAGE_NAME.RESEARCH_DASHBOARD}`,
          }}
        />
        <div className="d-block">
          {getProspectResearchProjectsForRespondentRes.status ===
          status.IN_PROGRESS ? (
            <div
              className="text-center d-flex align-items-center justify-content-center"
              style={{ height: "83vh" }}
            >
              <Loading />
            </div>
          ) : researchProjects.length ? (
            <div className="researchProjects-container">
              <div className="finalized-cards">
                <div className="row g-3 align-items-center">
                  {researchProjects.map((researchProject, index) => {
                    return (
                      <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                        <Link
                          to={`/app/postlogin/research-project/${researchProject.projectId._id}`}
                        >
                          <Card className="finalized-card p-3" key={index}>
                            <div className="d-block mb-2 w-100">
                              <h5 className="finalized-title d-block w-100">
                                {" "}
                                {researchProject.projectId.title}
                              </h5>
                              <span className="finalized-description d-block w-100">
                                {researchProject.projectId.description}
                              </span>
                            </div>
                            <p className="d-block w-100">
                              <strong>Incentive : </strong> &#8377;
                              {researchProject.projectId.insentive}
                            </p>
                            <p className="d-block w-100">
                              <strong>Time Required : </strong>
                              {researchProject.projectId.timeRequired} Minutes
                            </p>
                          </Card>
                        </Link>
                      </div>
                    );
                  })}{" "}
                </div>
              </div>
            </div>
          ) : (
            <div
              className="text-center d-flex align-items-center justify-content-center"
              style={{ height: "83vh" }}
            >
              <h4 className="description">
                {getProspectResearchProjectsForRespondentRes.status ===
                status.FAILURE
                  ? getProspectResearchProjectsForRespondentRes?.data ===
                    "Rejected"
                    ? "There is some internal server error."
                    : getProspectResearchProjectsForRespondentRes?.data
                  : "No research projects found."}
              </h4>
            </div>
          )}
        </div>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  const { getProspectResearchProjectsForRespondentRes } = state.researchProject;
  return { getProspectResearchProjectsForRespondentRes };
};

const mapDispatchToProps = {
  getProspectResearchProjectsForRespondent,
};

export default navigateRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResearchProjectsAvailable)
);
