import React, { Component } from "react";
import {
  Autocomplete,
  TextField,
  FormHelperText,
  FormControl,
} from "@mui/material";

export class CustomMultipleSelect extends Component {
  handleStateChange = (event, value) => {
    this.props.handleStateChange(value);
  };

  render() {
    const {
      errorMsg,
      details,
      styleDetails = {},
      question,
      description,
      opts = [],
      ans = [],
      isDisabled = false,
    } = this.props;

    return (
      <div className="d-block choice-question-container">
        <div className="mb-3">
          <h3
            style={{
              color: styleDetails.questionColor,
              fontSize: `${styleDetails.questionFontSize}px`,
              fontFamily: styleDetails.questionFontFamily,
            }}
          >
            {question} {details?.isMandatory ? "*" : ""}
          </h3>
          <span
            style={{
              color: styleDetails.descriptionColor,
              fontSize: `${styleDetails.descriptionFontSize}px`,
              fontFamily: styleDetails.descriptionFontFamily,
            }}
          >
            {description}
          </span>
        </div>

        <div className="d-block select-multi-choice-container">
          <FormControl
            variant="outlined"
            className="select-container d-block form-group"
            fullWidth
          >
            <Autocomplete
              multiple
              options={opts}
              value={ans}
              onChange={this.handleStateChange}
              disabled={isDisabled}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  InputLabelProps={{
                    style: {
                      color: styleDetails.labelColor,
                      fontFamily: styleDetails.labelFontFamily,
                      fontSize: `${styleDetails.labelFontSize}px`,
                    },
                  }}
                  inputProps={{
                    ...params.inputProps,
                    style: {
                      color: styleDetails.inputColor,
                      fontFamily: styleDetails.inputFontFamily,
                      fontSize: `${styleDetails.inputFontSize}px`,
                    },
                  }}
                />
              )}
              isOptionEqualToValue={(option, value) => option === value}
              style={{
                boxShadow: `inset 0 0 0 2px ${styleDetails.selectBorderColor}`,
                borderRadius: styleDetails.borderRadius,
              }}
            />
          </FormControl>
          {errorMsg ? (
            <FormHelperText
              className="MuiFormHelperText-root Mui-error"
              style={{
                color: styleDetails.errorColor,
                fontSize: styleDetails.errorFontSize,
                fontFamily: styleDetails.errorFontFamily,
              }}
            >
              {errorMsg}
            </FormHelperText>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
}
