import React, { Component } from "react";
import { connect } from "react-redux";
import {
  CustomMultipleChoice,
  CustomSingleChoice,
  CustomFreeText,
  CustomMultipleSelect,
  CustomSelect,
} from "../_answercomponents";
import { Button } from "@mui/material";
import {
  PAGE_NAME,
  status,
  COMPARATOR_TYPES,
  RESEARCH_PROJECT_CONSTANT,
} from "Constants";
import {
  getResearchProjectDetailsById,
  saveResearchProjectScreeningQuestion,
} from "ReduxStore/researchProject/researchProjectThunk";
import Header from "Postlogin/Components/Header";
import { Loading } from "Postlogin/Components/Loading";
import { LoadingButton } from "@mui/lab";
import { alert } from "Utils/alert";
import { Box } from "@mui/material";
import { navigateRouter } from "Utils/navigateRouter";

class FillResearchProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentQuestion: 0,
      researchProjectDetails: {},
      isWelcomePageShown: false,
      isThankYouPageShown: false,
      errorData: {
        isValid: true,
        message: "",
      },
      showSubmitBtn: false,
      isDisabled: false,
    };
  }

  componentDidMount() {
    const id = this.props.params.id;
    this.props.getResearchProjectDetailsById(id);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.getResearchProjectDetailsByIdRes.status !==
      prevProps.getResearchProjectDetailsByIdRes.status
    ) {
      if (
        this.props.getResearchProjectDetailsByIdRes.status === status.SUCCESS
      ) {
        const researchProjectDetails =
          this.props.getResearchProjectDetailsByIdRes.data.projectDetails || {};
        let { isWelcomePageShown } = this.state;
        if (researchProjectDetails) {
          const { welcome } = researchProjectDetails;
          if (welcome?.header || welcome?.description || welcome?.image) {
            isWelcomePageShown = true;
          } else {
            isWelcomePageShown = false;
          }
        }
        const manipulatedData = this.manipulateData(
          JSON.parse(JSON.stringify(researchProjectDetails))
        );
        this.setState({
          researchProjectDetails: manipulatedData,
          currentQuestion: 0,
          isWelcomePageShown,
        });
      } else if (
        this.props.getResearchProjectDetailsByIdRes.status === status.FAILURE
      ) {
        alert.error(this.props.getResearchProjectDetailsByIdRes.data);
      }
    }

    if (
      this.props.saveResearchProjectScreeningQuestionRes.status !==
      prevProps.saveResearchProjectScreeningQuestionRes.status
    ) {
      if (
        this.props.saveResearchProjectScreeningQuestionRes.status ===
        status.SUCCESS
      ) {
        const { researchProjectDetails } = this.state;
        let outro = researchProjectDetails?.outro || {};
        let isOutroExist = outro?.header || outro?.description || outro?.image;
        if (isOutroExist) {
          this.setState({
            isWelcomePageShown: false,
            isThankYouPageShown: true,
          });
        } else {
          alert.success(
            this.props.saveResearchProjectScreeningQuestionRes.data.message
          );
          this.props.navigate(
            `/app/postlogin/${PAGE_NAME.RESEARCH_DASHBOARD}`,
            {
              replace: true,
            }
          );
        }
      } else if (
        this.props.saveResearchProjectScreeningQuestionRes.status ===
        status.FAILURE
      ) {
        alert.error(this.props.saveResearchProjectScreeningQuestionRes.data);
      }
    }
  }

  manipulateData = (researchProjectDetails) => {
    const { questions } = researchProjectDetails;
    questions.forEach((question) => {
      const { type } = question;
      if (
        type ===
        RESEARCH_PROJECT_CONSTANT.SCREENING_QUESTION_TYPE.MULTIPLE_CHOICE
      ) {
        question.ans = [];
      } else if (
        type === RESEARCH_PROJECT_CONSTANT.SCREENING_QUESTION_TYPE.FREE_TEXT ||
        type === RESEARCH_PROJECT_CONSTANT.SCREENING_QUESTION_TYPE.SINGLE_CHOICE
      ) {
        question.ans = "";
      }
      question.isShow = true;
    });
    return researchProjectDetails;
  };

  handleStateChange = (ans) => {
    let { currentQuestion, researchProjectDetails } = this.state;
    const { questions } = researchProjectDetails;
    if (questions && questions.length > 0) {
      const activeQuestion = questions[currentQuestion];
      activeQuestion.ans = ans;
      this.setState({
        researchProjectDetails,
      });
    }
  };

  renderQuestions = () => {
    const { currentQuestion, researchProjectDetails, errorData } = this.state;
    if (researchProjectDetails.questions?.length > 0) {
      const question = researchProjectDetails.questions[currentQuestion];
      const styleDetails = researchProjectDetails.styleDetails
        ? researchProjectDetails.styleDetails
        : {};
      const type = question.type;

      if (
        type ===
          RESEARCH_PROJECT_CONSTANT.SCREENING_QUESTION_TYPE.MULTIPLE_CHOICE &&
        question.isShow
      ) {
        return (
          <CustomMultipleChoice
            {...question}
            styleDetails={styleDetails}
            handleStateChange={this.handleStateChange}
            errorMsg={errorData.message}
            isValid={errorData.isValid}
            isDisabled={this.state.isDisabled}
          />
        );
      } else if (
        type ===
          RESEARCH_PROJECT_CONSTANT.SCREENING_QUESTION_TYPE.SINGLE_SELECT &&
        question.isShow
      ) {
        return (
          <CustomSelect
            {...question}
            styleDetails={styleDetails}
            handleStateChange={this.handleStateChange}
            errorMsg={errorData.message}
            isValid={errorData.isValid}
            isDisabled={this.state.isDisabled}
          />
        );
      } else if (
        type ===
          RESEARCH_PROJECT_CONSTANT.SCREENING_QUESTION_TYPE.MULTI_SELECT &&
        question.isShow
      ) {
        return (
          <CustomMultipleSelect
            {...question}
            styleDetails={styleDetails}
            handleStateChange={this.handleStateChange}
            errorMsg={errorData.message}
            isValid={errorData.isValid}
            isDisabled={this.state.isDisabled}
          />
        );
      } else if (
        type === RESEARCH_PROJECT_CONSTANT.SCREENING_QUESTION_TYPE.FREE_TEXT &&
        question.isShow
      ) {
        return (
          <CustomFreeText
            {...question}
            styleDetails={styleDetails}
            handleStateChange={this.handleStateChange}
            errorMsg={errorData.message}
            isValid={errorData.isValid}
            isDisabled={this.state.isDisabled}
          />
        );
      } else if (
        type ===
          RESEARCH_PROJECT_CONSTANT.SCREENING_QUESTION_TYPE.SINGLE_CHOICE &&
        question.isShow
      ) {
        return (
          <CustomSingleChoice
            {...question}
            styleDetails={styleDetails}
            handleStateChange={this.handleStateChange}
            errorMsg={errorData.message}
            isValid={errorData.isValid}
            isDisabled={this.state.isDisabled}
          />
        );
      }
      return <></>;
    }
  };

  validateCurrentQuestion = () => {
    let errorData = {
      isValid: true,
      message: "",
    };
    const { researchProjectDetails, currentQuestion } = this.state;
    const { questions } = researchProjectDetails;
    if (questions[currentQuestion]) {
      const activeQuestion = questions[currentQuestion];
      const { ans, details, type, opts } = activeQuestion;
      if (details) {
        const {
          mandatoryMessage,
          isMandatory,
          isCaseSensitive,
          validationRegex,
          validationMessage,
        } = details;
        if (isMandatory) {
          if (
            type ===
              RESEARCH_PROJECT_CONSTANT.SCREENING_QUESTION_TYPE
                .MULTIPLE_CHOICE ||
            RESEARCH_PROJECT_CONSTANT.SCREENING_QUESTION_TYPE.MULTI_SELECT
          ) {
            if (!ans || (ans && ans.length === 0)) {
              errorData = {
                isValid: false,
                message: mandatoryMessage,
              };
            }
          } else if (
            type ===
              RESEARCH_PROJECT_CONSTANT.SCREENING_QUESTION_TYPE.FREE_TEXT ||
            type ===
              RESEARCH_PROJECT_CONSTANT.SCREENING_QUESTION_TYPE.SINGLE_CHOICE ||
            RESEARCH_PROJECT_CONSTANT.SCREENING_QUESTION_TYPE.SINGLE_SELECT
          ) {
            if (!ans || ans?.trim() === "") {
              errorData = {
                isValid: false,
                message: mandatoryMessage,
              };
            }
          }
        }
        if (errorData.isValid && validationRegex) {
          if (
            type === RESEARCH_PROJECT_CONSTANT.SCREENING_QUESTION_TYPE.FREE_TEXT
          ) {
            const regex = isCaseSensitive
              ? new RegExp(`^${details?.validationRegex}$`)
              : new RegExp(`^${details?.validationRegex}$`, "i");
            const isValid = regex.test(ans);
            if (!isValid) {
              errorData = {
                isValid,
                message: validationMessage,
              };
            }
          }
        }
      }
    }
    return errorData;
  };

  onClickNext = async () => {
    const { currentQuestion, researchProjectDetails, isWelcomePageShown } =
      this.state;
    let outro = researchProjectDetails?.outro || {};
    let isOutroExist = outro?.header || outro?.description || outro?.image;

    if (isWelcomePageShown) {
      this.setState({
        isWelcomePageShown: false,
        currentQuestion: 0,
      });
      return;
    }
    if (!this.state.isDisabled) {
      const errorData = this.validateCurrentQuestion();
      if (errorData.isValid) {
        await this.viewLogicImplement();
        this.setState({
          errorData,
        });
      } else {
        this.setState({
          errorData,
        });
      }
    } else if (
      currentQuestion !==
      researchProjectDetails.questions.length - 1
    ) {
      this.setState({ currentQuestion: currentQuestion + 1 });
    } else if (isOutroExist && this.state.isDisabled) {
      this.setState({ isThankYouPageShown: true });
    }
  };

  onClickPrevious = () => {
    let {
      currentQuestion,
      researchProjectDetails,
      isWelcomePageShown,
      isThankYouPageShown,
      isDisabled,
    } = this.state;
    const { questions, welcome } = researchProjectDetails;
    if (isThankYouPageShown && isDisabled) {
      this.setState({ isThankYouPageShown: false });
      return;
    }
    if (currentQuestion === 0) {
      if (welcome?.header || welcome?.description || welcome?.image) {
        isWelcomePageShown = true;
      } else {
        isWelcomePageShown = false;
      }
    }
    this.setState({ isWelcomePageShown });

    if (currentQuestion - 1 >= 0) {
      if (!questions[currentQuestion - 1]?.isShow) {
        for (
          let currentIndex = this.state.currentQuestion - 1;
          currentIndex > 0;
          currentIndex--
        ) {
          const element = questions[currentIndex];
          if (element.isShow) {
            currentQuestion = currentIndex;
            break;
          }
        }
      } else {
        currentQuestion = currentQuestion - 1;
      }
      this.setState(
        {
          currentQuestion,
          showSubmitBtn: false,
        },
        () => {
          if (!this.state.isDisabled) {
            const errorData = this.validateCurrentQuestion();
            this.setState({ errorData });
          }
        }
      );
      return;
    }
  };

  onClickBackToDashboard = () => {
    // redirect user to dashboard
    this.props.navigate("/app/postlogin/dashboard", { replace: true });
  };

  viewLogicImplement = async () => {
    let { researchProjectDetails, showSubmitBtn } = this.state;
    if (researchProjectDetails?.questions?.length) {
      let currentQuestion =
        researchProjectDetails?.questions?.[this.state.currentQuestion];

      let questions = JSON.parse(
        JSON.stringify(researchProjectDetails?.questions || [])
      );

      if (questions?.length) {
        questions = questions.map((question) => {
          if (question?.display?.length) {
            let isShow = this.setLogicViewHide(
              question.display,
              currentQuestion?._id,
              currentQuestion?.ans || "",
              currentQuestion?.type
            );

            if (isShow === false || isShow === true) {
              question["isShow"] = isShow;
            }
          }

          if (question?.skip?.length) {
            let isHide = this.setLogicViewHide(
              question.skip,
              currentQuestion?._id,
              currentQuestion?.ans || "",
              currentQuestion?.type
            );

            if (isHide === false || isHide === true) {
              question["isShow"] = isHide ? false : true;
            }
          }

          return question;
        });
      }

      if (this.state.currentQuestion + 1 < questions.length) {
        if (!questions[this.state.currentQuestion + 1]?.isShow) {
          let showIndex = "";
          for (
            let currentIndex = this.state.currentQuestion + 1;
            currentIndex < questions.length;
            currentIndex++
          ) {
            const element = questions[currentIndex];
            if (element.isShow) {
              showIndex = currentIndex;
              showSubmitBtn = false;
              break;
            } else {
              questions = questions.map((question) => {
                if (question?.display?.length) {
                  let isShow = this.setLogicViewHide(
                    question.display,
                    question[currentIndex]?._id,
                    question[currentIndex]?.ans,
                    question[currentIndex]?.type
                  );

                  if (isShow === false || isShow === true) {
                    question["isShow"] = isShow;
                  }
                }

                if (question?.skip?.length) {
                  let isHide = this.setLogicViewHide(
                    question.skip,
                    question[currentIndex]?._id,
                    question[currentIndex]?.ans,
                    question[currentIndex]?.type
                  );

                  if (isHide === false || isHide === true) {
                    question["isShow"] = isHide ? false : true;
                  }
                }

                return question;
              });
              if (currentIndex === questions.length - 1) {
                showSubmitBtn = true;
              }
            }
          }

          if (showIndex) {
            this.setState({
              currentQuestion: showIndex,
              showSubmitBtn,
            });
          } else if (showSubmitBtn) {
            questions = questions.map((question) => {
              delete question.isShow;
              return question;
            });
            const sendData = {
              questions,
              projectId: this.props.params.id,
            };
            this.props.saveResearchProjectScreeningQuestion(sendData);
          }
        } else {
          this.setState({
            currentQuestion: this.state.currentQuestion + 1,
          });
        }
      }

      if (this.state.currentQuestion === questions.length - 1) {
        questions = questions.map((question) => {
          delete question.isShow;
          return question;
        });
        const sendData = {
          questions,
          projectId: this.props.params.id,
        };
        this.props.saveResearchProjectScreeningQuestion(sendData);
        // Call the api to save the whole data.
      }
      let remainingQuestions = questions?.slice(this.state.currentQuestion + 1);

      if (remainingQuestions?.length) {
        remainingQuestions.forEach((remainingQuestion) => {
          questions = questions.map((question) => {
            if (question?.display?.length) {
              let isShow = this.setLogicViewHide(
                question.display,
                remainingQuestion?._id,
                remainingQuestion?.ans,
                remainingQuestion?.type
              );

              if (isShow === false || isShow === true) {
                question["isShow"] = isShow;
              }
            }

            if (question?.skip?.length) {
              let isHide = this.setLogicViewHide(
                question.skip,
                remainingQuestion?._id,
                remainingQuestion?.ans,
                remainingQuestion?.type
              );

              if (isHide === false || isHide === true) {
                question["isShow"] = isHide ? false : true;
              }
            }

            return question;
          });
        });
      }
      showSubmitBtn = remainingQuestions?.length
        ? questions
            ?.slice(this.state.currentQuestion + 2)
            .every((question) => question.isShow === false)
        : showSubmitBtn;
      // clear data when isShow = false
      questions = questions.map((question) => {
        if (question.isShow === false) {
          const { type } = question;
          if (
            type ===
            RESEARCH_PROJECT_CONSTANT.SCREENING_QUESTION_TYPE.MULTIPLE_CHOICE
          ) {
            question.ans = [];
          } else if (
            type ===
              RESEARCH_PROJECT_CONSTANT.SCREENING_QUESTION_TYPE.FREE_TEXT ||
            type ===
              RESEARCH_PROJECT_CONSTANT.SCREENING_QUESTION_TYPE.SINGLE_CHOICE
          ) {
            question.ans = "";
          }
        }
        return question;
      });
      this.setState({
        researchProjectDetails: {
          ...this.state.researchProjectDetails,
          questions,
        },
        showSubmitBtn,
      });
    }
    return 1;
  };

  setLogicViewHide = (data, id, ans, type) => {
    let ansLogicalArr = [];
    if (data?.length) {
      data.forEach((logic, index) => {
        let isValid = "";
        if (logic.question && logic.question === id) {
          if (logic.comparator === COMPARATOR_TYPES?.IS_ANSWERED?.value) {
            if (
              [
                RESEARCH_PROJECT_CONSTANT.SCREENING_QUESTION_TYPE
                  .MULTIPLE_CHOICE,
              ].includes(type)
            ) {
              if (ans?.length) {
                isValid = true;
              } else {
                isValid = false;
              }
            } else if (
              [
                RESEARCH_PROJECT_CONSTANT.SCREENING_QUESTION_TYPE.FREE_TEXT,
                RESEARCH_PROJECT_CONSTANT.SCREENING_QUESTION_TYPE.SINGLE_CHOICE,
              ].includes(type)
            ) {
              if (ans) {
                isValid = true;
              } else {
                isValid = false;
              }
            }
            // is not answered
          } else if (
            logic.comparator === COMPARATOR_TYPES?.IS_NOT_ANSWERED?.value
          ) {
            if (
              [
                RESEARCH_PROJECT_CONSTANT.SCREENING_QUESTION_TYPE
                  .MULTIPLE_CHOICE,
              ].includes(type)
            ) {
              if (!ans || ans?.length === 0) {
                isValid = true;
              } else {
                isValid = false;
              }
            } else if (
              [
                RESEARCH_PROJECT_CONSTANT.SCREENING_QUESTION_TYPE.FREE_TEXT,
                RESEARCH_PROJECT_CONSTANT.SCREENING_QUESTION_TYPE.SINGLE_CHOICE,
              ].includes(type)
            ) {
              if (!ans) {
                isValid = true;
              } else {
                isValid = false;
              }
            }
          } else if (logic.comparator === COMPARATOR_TYPES?.CONTAINS?.value) {
            if (ans && ans?.includes(logic?.value)) {
              isValid = true;
            } else {
              isValid = false;
            }
          } else if (
            logic.comparator === COMPARATOR_TYPES?.DOES_NOT_CONTAIN?.value
          ) {
            if (ans && !ans?.includes(logic?.value)) {
              isValid = true;
            } else {
              isValid = false;
            }
          } else if (logic.comparator === COMPARATOR_TYPES?.EQUALS?.value) {
            if (ans && String(ans) === String(logic?.value)) {
              isValid = true;
            } else {
              isValid = false;
            }
          } else if (
            logic.comparator === COMPARATOR_TYPES?.IS_NOT_EQUAL_TO?.value
          ) {
            if (ans && String(ans) !== String(logic?.value)) {
              isValid = true;
            } else {
              isValid = false;
            }
          } else if (
            logic.comparator === COMPARATOR_TYPES?.STARTS_WITH?.value
          ) {
            if (String(ans).startsWith(String(logic.value))) {
              isValid = true;
            } else {
              isValid = false;
            }
          } else if (logic.comparator === COMPARATOR_TYPES?.ENDS_WITH?.value) {
            if (String(ans).endsWith(String(logic.value))) {
              isValid = true;
            } else {
              isValid = false;
            }
          } else if (logic.comparator === COMPARATOR_TYPES?.SELECTS?.value) {
            if (
              [
                RESEARCH_PROJECT_CONSTANT.SCREENING_QUESTION_TYPE
                  .MULTIPLE_CHOICE,
              ].includes(type)
            ) {
              if (ans?.length) {
                isValid = ans.some((value) => logic?.value.includes(value));
              } else {
                isValid = false;
              }
            } else if (
              [
                RESEARCH_PROJECT_CONSTANT.SCREENING_QUESTION_TYPE.SINGLE_CHOICE,
              ].includes(type)
            ) {
              if (logic?.value?.includes(ans)) {
                isValid = true;
              } else {
                isValid = false;
              }
            }
          } else if (logic.comparator === COMPARATOR_TYPES?.NOT_SELECT?.value) {
            if (
              [
                RESEARCH_PROJECT_CONSTANT.SCREENING_QUESTION_TYPE
                  .MULTIPLE_CHOICE,
              ].includes(type)
            ) {
              if (ans?.length) {
                isValid = ans.some((value) => !logic?.value.includes(value));
              } else {
                isValid = false;
              }
            } else if (
              [
                RESEARCH_PROJECT_CONSTANT.SCREENING_QUESTION_TYPE.SINGLE_CHOICE,
              ].includes(type)
            ) {
              if (!logic?.value?.includes(ans)) {
                isValid = true;
              } else {
                isValid = false;
              }
            }
          } else if (logic.comparator === COMPARATOR_TYPES?.IN_BETWEEN?.value) {
            if (+logic?.value?.[0] < ans && +logic?.value?.[1] > ans) {
              isValid = true;
            } else {
              isValid = false;
            }
          } else if (
            logic.comparator === COMPARATOR_TYPES?.IS_NOT_IN_BETWEEN?.value
          ) {
            if (!(+logic?.value?.[0] < ans && +logic?.value?.[1] > ans)) {
              isValid = true;
            } else {
              isValid = false;
            }
          }
        }
        if (data.length - 1 !== index) {
          let logicalCheck =
            logic.join === 0 ? "&&" : logic.join === 1 ? "||" : "";
          if (isValid === true || isValid === false) {
            ansLogicalArr.push(isValid);
            if (logicalCheck) {
              ansLogicalArr.push(logicalCheck);
            }
          }
        } else if (data.length - 1 === index) {
          if (isValid === true || isValid === false) {
            ansLogicalArr.push(isValid);
          }
        }
      });
    }

    return ansLogicalArr.length ? this.evaluateLogicalArray(ansLogicalArr) : "";
  };

  evaluateLogicalArray = (arr) => {
    // Start with the first boolean value as the initial result
    let result = arr[0];
    // Iterate over the array, skipping the boolean values and applying operators
    for (let i = 1; i < arr.length; i += 2) {
      let operator = arr[i];
      let nextValue = arr[i + 1];
      if (operator === "&&") {
        result = result && nextValue;
      } else if (operator === "||") {
        result = result || nextValue;
      }
    }
    return result;
  };

  render() {
    const { getResearchProjectDetailsByIdRes } = this.props;
    let {
      researchProjectDetails,
      isWelcomePageShown,
      isThankYouPageShown,
      currentQuestion,
      showSubmitBtn,
    } = this.state;
    let outro = researchProjectDetails?.outro || {};
    let isOutroExist = outro?.header || outro?.description || outro?.image;

    return (
      <Box className="main-container">
        <Header
          data={{
            header: researchProjectDetails.title,
            page: PAGE_NAME.SURVEY,
            backUrl: `/app/postlogin/${PAGE_NAME.RESEARCH_PROJECTS_AVAILABLE}`,
          }}
        />
        {getResearchProjectDetailsByIdRes.status === status.IN_PROGRESS ? (
          <div
            className="text-center d-flex align-items-center justify-content-center"
            style={{ height: "83vh" }}
          >
            <Loading />
          </div>
        ) : (
          <>
            {getResearchProjectDetailsByIdRes.status === status.SUCCESS ? (
              <div className="view-questionnaires">
                <div className="survey-container">{this.renderQuestions()}</div>
                <div className="survey-buttons" justifyContent={"flex-end"}>
                  {isWelcomePageShown ||
                  (isThankYouPageShown && !this.state.isDisabled) ? (
                    <></>
                  ) : (
                    <Button
                      className="btn-primary-outlined me-3"
                      variant="outlined"
                      onClick={this.onClickPrevious}
                    >
                      Previous
                    </Button>
                  )}
                  {isThankYouPageShown && !this.state.isDisabled ? (
                    <Button
                      style={{ width: "auto" }}
                      className="btn-primary-outlined px-5"
                      variant="outlined"
                      onClick={this.onClickBackToDashboard}
                    >
                      Back To Dashboard
                    </Button>
                  ) : isWelcomePageShown ||
                    (currentQuestion ===
                      researchProjectDetails.questions?.length - 1 &&
                      ((this.state.isDisabled && !isOutroExist) ||
                        (this.state.isDisabled && isThankYouPageShown))) ? (
                    <></>
                  ) : (
                    <LoadingButton
                      className="btn-primary"
                      variant="contained"
                      onClick={this.onClickNext}
                      disabled={
                        this.props.saveResearchProjectScreeningQuestionRes
                          .status === status.IN_PROGRESS
                      }
                      loading={
                        this.props.saveResearchProjectScreeningQuestionRes
                          .status === status.IN_PROGRESS
                      }
                    >
                      {(currentQuestion ===
                        researchProjectDetails.questions?.length - 1 ||
                        showSubmitBtn) &&
                      !this.state.isDisabled
                        ? "Submit"
                        : "Next"}
                    </LoadingButton>
                  )}
                </div>
              </div>
            ) : null}
          </>
        )}
      </Box>
    );
  }
}

function mapStateToProps(state) {
  const {
    getResearchProjectDetailsByIdRes,
    saveResearchProjectScreeningQuestionRes,
  } = state.researchProject;
  return {
    getResearchProjectDetailsByIdRes,
    saveResearchProjectScreeningQuestionRes,
  };
}

const mapDispatchToProps = {
  saveResearchProjectScreeningQuestion,
  getResearchProjectDetailsById,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(navigateRouter(FillResearchProject));
